var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tableData)?_c('fragment',[_c('section',{staticClass:"control"},[_c('div',{staticClass:"con_ti"}),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":this.addExpense}},[_vm._v("新增")])],1)]),(_vm.tableData)?_c('section',{staticClass:"tabulation"},[_c('Tabulation',{attrs:{"data":{
      columnName: [
        {name: '费用项名称', prop: 'costName', type: 'text', needSort: false},
      ],
      rows: this.tableData,
      control: [
        {name: '修改', type: 'warning', method: this.updateExpense},
        {name: '删除', type: 'danger', method: this.delExpense},
      ]
    }}})],1):_vm._e()]):_c('fragment',[_c('img',{staticClass:"loading",attrs:{"src":require("../../../assets/images/loading.gif"),"height":"100","width":"100","alt":"加载中"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }