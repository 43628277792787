<script>
import Tabulation from "@/view/tabulate/Tabulate.vue";

export default {
  components: {Tabulation},
  data(){
    return{
      tableData: null,
    }
  },
  created() {
    this.getTableData()
  },
  methods:{
    /**
     * 获取字典数据
     */
    getTableData(){
      this.axios.get(this.$api.publicUrl.ExpenseList).then(res =>{
        if(res.data.status === 200){
          this.tableData = res.data.data
        }else {
          this.$message.error(res.data.message)
        }
      })
    },
    /**
     * 1、创建模板数据； 2、调用创建弹出层的方法
     * @param ti  {string}  标题
     * @param mod {string}  方式；例如 添加、修改
     * @param data  {Object}  源数据； 最终弹出层的回调事件会将修改后的源数据返回，以便于后续提交表单数据
     */
    createTemplateData(ti, mod, data = null){
      this.$createPopup("form",{
        ti: ti,
        tree: [
          [{label: "费用项名称", type: "text", key: "costName", prop: "costName"}]
        ],
        formData: data || {
          costName: ""
        },
        rules: {
          costName: [{ required: true, message: '请输入费用项名称', trigger: 'blur' }],
        }
      }, mod, this.poPupCallback)
    },
    /**
     * 新增
     */
    addExpense(){
      this.createTemplateData("新增费用项", "add")
    },
    /**
     * 修改
     */
    updateExpense(data){
      this.createTemplateData("修改费用项","update", data)
    },
    /**
     * 删除
     */
    delExpense(id){
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.sendService(id, "del")
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    /**
     * 弹出层回调事件
     * 这里可能需要针对某些接口做数据预处理， 最后将数据发送给服务器
     * @param data
     * @param mode
     */
    poPupCallback(data, mode){
      this.sendService(data, mode)
    },
    /**
     * 向服务器发送请求，以及回调的状态处理
     * @param data
     * @param mode
     */
    sendService(data, mode){
      new Promise((resolve, reject)=>{
        switch (mode){
          case "add":
            this.axios.post(this.$api.publicUrl.ExpenseList, data).then(res =>{
              if(res.data.status === 200){
                resolve("添加成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "update":
            this.axios.put(this.$api.publicUrl.ExpenseList, data).then(res =>{
              if(res.data.status === 200){
                resolve("修改成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
          case "del":
            this.axios.delete(this.$api.publicUrl.ExpenseList + "/" + data ).then(res =>{
              if(res.data.status === 200){
                resolve("删除成功!")
              }else {
                reject(res.data.message)
              }
            })
            break
        }
      }).then(res =>{
        this.getTableData()
        this.$message({
          message: res,
          type: 'success'
        })
      }).catch(err =>{
        this.$message.error(err);
      })
    }
  }
}
</script>

<template>
  <fragment v-if="tableData">
    <section class="control">
      <div class="con_ti"></div>
      <div class="btn">
        <el-button type="primary" @click="this.addExpense" size="small">新增</el-button>
      </div>
    </section>
    <section class="tabulation" v-if="tableData">
      <Tabulation :data="{
        columnName: [
          {name: '费用项名称', prop: 'costName', type: 'text', needSort: false},
        ],
        rows: this.tableData,
        control: [
          {name: '修改', type: 'warning', method: this.updateExpense},
          {name: '删除', type: 'danger', method: this.delExpense},
        ]
      }"></Tabulation>
    </section>
  </fragment >
  <fragment v-else>
    <img src="../../../assets/images/loading.gif" class="loading" height="100" width="100" alt="加载中"/>
  </fragment>
</template>

<style src="@/assets/css/componentStyle.css" scoped></style>
